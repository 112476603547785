<!-- 科室管理 -->
<template>
	<div class="doctor_management">
	<el-row class="bread_crumbs">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/PersonnelManagement' }">组织结构管理</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/PersonnelManagement' }">科室列表</el-breadcrumb-item>
		</el-breadcrumb>
	</el-row>
	<div class="public_bg">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="80px" class="demo-form-inline">
								<el-form-item label="科室名称:" prop="departmentsName">
									<el-input clearable v-model="formInline.departmentsName" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="所属医院:" prop="hospitalId">
									<el-select clearable filterable ref="selection" style="width:100%"  v-model="formInline.hospitalId" placeholder="请选择">
										<el-option
										  v-for="item in HospitalOptions"
										  :key="item.no"
										  :label="item.label"
										  :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="querys">查询</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button icon="el-icon-plus" type="primary" @click="NewAdd('新增',{})">新增</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table :height="Height" stripe ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}"
							 :data="DepartmentsPageData" style="width: 100%;">
								<el-table-column
								  label="序号"
								  type="index"
								  width="50"
								  align="left">
								</el-table-column>
								<el-table-column align="left" prop="departmentsName" label="科室">
								</el-table-column>
								<el-table-column align="left" prop="hospitalName" label="医院">
								</el-table-column>
								<el-table-column
								  label="操作">
								  <template slot-scope="scope">
									  <el-button type="text" :class="scope.row.status!=='200000' ? 'infos' :'primarys'" @click="NewAdd('编辑',scope.row)">编辑</el-button>
								    <el-button type="text" :class="scope.row.status=='200000' ? 'dangers' :'primarys'" @click="NewAdd('状态',scope.row)">{{scope.row.status=='200000' ? '停用' :'启用'}}</el-button>
								  </template>
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
		<el-dialog
		  :title="title"
		  :visible.sync="DialogVisible"
		  width="40%"
		  >
		    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="医院全称:" prop="hospitalId">
					<el-select :disabled="title=='编辑科室' ? true : false" filterable ref="selection" style="width:100%"  v-model="ruleForm.hospitalId" placeholder="请选择">
						<el-option
						  v-for="item in HospitalOptions"
						  :key="item.no"
						  :label="item.label"
						  :value="item.no">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- 先选择医院再选择科室 -->
			  <el-form-item label="科室名称:" prop="departmentsName">
				<el-input v-model="ruleForm.departmentsName" placeholder="请输入"></el-input>
			  </el-form-item>
			  <el-form-item label="科室类型:" prop="departmentsType">
				 <el-select filterable ref="selection" style="width:100%"  v-model="ruleForm.departmentsType" placeholder="请选择">
				 	<el-option
				 	  v-for="item in DepartmentTypeOptions"
				 	  :key="item.no"
				 	  :label="item.label"
				 	  :value="item.no">
				 	</el-option>
				 </el-select>
			  </el-form-item>
			 </el-form>
		  <span slot="footer" class="dialog-footer">
			<el-button @click="DialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="DialogVisibleSave">确 认</el-button>
		  </span>
		</el-dialog>
		</div>
	</div>
</template>

<script>
    import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//医院接口
			async QueryHospital(){
				let obj={
					menuId: this.menuCode,
				}
			    let res = await this.$Http.Hospital(obj);
				if (res.code == '200') {
					this.HospitalOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//科室接口
			async QueryDepartmentType(){
			    let res = await this.$Http.DepartmentType({parentCode:"100010"});
				if (res.code == '200') {
					this.DepartmentTypeOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//新增接口
			async QueryDepartmentsInsertBy(){
			    let info={
					hospitalId:this.ruleForm.hospitalId,
			  		departmentsType:this.ruleForm.departmentsType,
					departmentsName:this.ruleForm.departmentsName
			  	}
			    let res = await this.$Http.DepartmentsInsertByInfo(info);
				this.DialogVisible=false;
				if (res.code == '200') {
					this.QueryDepartmentsQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//修改接口
			async QueryDepartmentsUpdateBy(){
			    let info={
					hospitalId:this.ruleForm.hospitalId,
					departmentsType:this.ruleForm.departmentsType,
					departmentsName:this.ruleForm.departmentsName,
					departmentsId:this.ruleForm.departmentsId,
					status:this.status
			  	}
			    let res = await this.$Http.DepartmentsUpdateByInfo(info);
				this.DialogVisible=false;
				if (res.code == '200') {
					this.QueryDepartmentsQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//编辑新增
			NewAdd(val,item){
				this.ruleForm = JSON.parse(JSON.stringify(item));
				if(val=="状态"){
					item.status=="200000" ? this.status="200001" : this.status="200000";
					this.QueryDepartmentsUpdateBy();
				}else{
					if(item.status=="200001"){
						this.$message.error("已停用,不可编辑");
						return false;
					}else{
						this.DialogVisible = true;
						this.title = `${val}科室`;
						this.status=item.status;
					}
					
				}
			},
			//保存
			DialogVisibleSave() {
				this.$refs["ruleForm"].validate((valid) => {
				  if (valid) {
					this.title=="新增科室" ? this.QueryDepartmentsInsertBy() : this.QueryDepartmentsUpdateBy();
                  } else {
					return false;
				  }
				});
			},
			// 搜索
			querys() {
              this.QueryDepartmentsQueryPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//分页接口
			async QueryDepartmentsQueryPage(current = 1, size = 10){
			    let info={
			  		current:current,
			  		size:size,
					departmentsName:this.formInline.departmentsName,
					hospitalId:this.formInline.hospitalId,
					menuId: this.menuCode,
			  	}
			    let res = await this.$Http.DepartmentsQueryPageInfo(info)
				if (res.code == '200') {
					this.DepartmentsPageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.DepartmentsPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//分页组件
			pages(current, size) {
				this.QueryDepartmentsQueryPage(current, size);
			},
		},
		data() {
			return {
                Height:document.documentElement.clientHeight-280,
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
                DepartmentTypeOptions:[],
				HospitalOptions:[],
				ruleForm:{},
				rules:{
					hospitalId: [
						{ required: true, message: '请选择医院全称', trigger: 'change' }
					  ],
					  departmentsName: [
						{ required: true, message: '请输入科室名称', trigger: 'blur' }
					  ],
					  departmentsType: [
						{ required: true, message: '请选择科室类型', trigger: 'change' }
					  ],
				},
				DialogVisible:false,
				//title
				title:"",
				//form表单查询
				formInline: {},
				//table列表
				DepartmentsPageData: [],
				//总条数
				total: 0,
			}
		},
		created() {
		},
		mounted() {
			//查询分页
			this.QueryDepartmentsQueryPage();
			//医院
			this.QueryHospital();
			//科室
			this.QueryDepartmentType();
			document.documentElement.clientHeight<=888 ? "" : this.Height=document.documentElement.clientHeight-280 ;
		}
	}
</script>

<style scoped>
</style>
